import styled from 'styled-components';

interface FeaturedImgProps {
  bgImg: string;
}

const FeaturedImg = styled.div<FeaturedImgProps>`
  padding-bottom: 100%;
  background-image: url('${props =>
    props.bgImg ? props.bgImg : '/images/design/hero/resources.png'}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export default FeaturedImg;
