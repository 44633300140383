import styled from 'styled-components';

const PostWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-bottom: calc(${props => props.theme.paddings.pd}px * 2.5);

  & img {
    width: 100%;
  }
`;

export default PostWrapper;
