import styled from 'styled-components';

const PaginationWrapper = styled.div`
  margin-top: ${props => props.theme.paddings.pd}px;

  & button {
    background-color: #80225f;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 55px;
  }
`;

export default PaginationWrapper;
