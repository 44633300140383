import React, { useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTheme } from 'styled-components';
import moment from 'moment';
import DateAndAuthor from '../components/formatting/DateAndAuthor';
import WithHeroLayout from '../layouts/WithHeroLayout';
import LinkButton from '../components/form-elements/LinkButton';
import SocialShareLinks from '../components/SocialShareLinks';
import { paginationImgs } from '../constants';
import PostInformationWrapper from '../components/journal/PostInformationWrapper';
import PostInformationContainer from '../components/journal/PostInformationContainer';
import PostContentWrapper from '../components/journal/PostContentWrapper';
import PostWrapper from '../components/journal/PostWrapper';
import FeaturedImg from '../components/journal/FeaturedImg';
import Excerpt from '../components/journal/Excerpt';
import PaginationWrapper from '../components/journal/PaginationWrapper';
import MDX from '../cms/MDX';

interface Props extends PageProps {
  data: {
    file: App.MDXType<App.Journal>;
  };
  pageContext: any;
}

const JournalTemplate: React.FC<Props> = function (props) {
  const { data, pageContext, location } = props;
  const {
    title,
    featured_image,
    excerpt,
    pub_date,
    dc_creator,
    content,
  } = data.file.childMdx.frontmatter;

  const prevArticle = {
    screenreaderText: 'Previous Article: ' + pageContext.prev?.title,
    link: 'journal' + pageContext.prev?.link,
  };
  const nextArticle = {
    screenreaderText: 'Next Article: ' + pageContext.next?.title,
    link: 'journal' + pageContext.next?.link,
  };

  const formattedDate = useMemo(() => moment(pub_date).format('LL'), [
    pub_date,
  ]);

  const theme = useTheme();

  return (
    <WithHeroLayout
      pageTitle={title}
      bgImgSrc={theme.colors.purple}
      color={theme.colors.purple}
      path={location?.pathname}
    >
      <PostWrapper>
        <PostInformationWrapper>
          <FeaturedImg bgImg={featured_image} />
          <PostInformationContainer>
            <h3>{title}</h3>
            <Excerpt>{excerpt}</Excerpt>

            <DateAndAuthor>
              {formattedDate}
              <br />
              by {dc_creator}
            </DateAndAuthor>
            <SocialShareLinks link={location.href} text={title} />
          </PostInformationContainer>
        </PostInformationWrapper>

        <PostContentWrapper>
          <MDX>{content}</MDX>

          <PaginationWrapper>
            {pageContext.prev != null ? (
              <LinkButton
                buttonType="primary"
                buttonText=""
                screenreaderText={prevArticle.screenreaderText}
                link={prevArticle.link}
                background={paginationImgs.leftArrow}
                noHoverEffects={true}
              />
            ) : null}
            {pageContext.next != null ? (
              <LinkButton
                buttonType="primary"
                buttonText=""
                screenreaderText={nextArticle.screenreaderText}
                link={nextArticle.link}
                background={paginationImgs.rightArrow}
                noHoverEffects={true}
              />
            ) : null}
          </PaginationWrapper>
        </PostContentWrapper>
      </PostWrapper>
    </WithHeroLayout>
  );
};

export const query = graphql`
  query($absolutePath: String!) {
    file(absolutePath: { eq: $absolutePath }) {
      childMdx {
        body
        frontmatter {
          link
          featured_image
          title
          content
          excerpt
          dc_creator
          pub_date
        }
      }
    }
  }
`;

export default JournalTemplate;
