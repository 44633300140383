import styled from 'styled-components';
import { device } from '../../constants';

const PostContentWrapper = styled.section`
  flex: 1 0 35%;

  padding-right: ${props => props.theme.paddings.pd * 1.5}px;
  padding-left: ${props => props.theme.paddings.pd * 1.5}px;

  @media screen and ${device.laptop} {
    padding: ${props => props.theme.paddings.pd}px
      ${props => props.theme.paddings.pd * 1.5}px;
  }
`;

export default PostContentWrapper;
