import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-family: 'Open Sans';
  margin-right: ${props => props.theme.paddings.pd}px;
`;


const DateAndAuthor: React.FC = function (props) {
  return <Wrapper>{props.children}</Wrapper>;
};

export default DateAndAuthor;