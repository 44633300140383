import React, { useContext } from 'react';
import styled from 'styled-components';
import SiteConfigContext from '../context/SiteConfigContext';

const SocialLinksWrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.paddings.halfpd}px;

  & > a:not(:last-child) {
    margin-right: ${props => props.theme.paddings.halfpd}px;
  }
`;

interface Props {
  link: string;
  text: string;
}
const SocialShareLinks: React.FC<Props> = function (props) {
  const { text, link } = props;
  const siteCfg = useContext(SiteConfigContext);
  const networksEnabled = siteCfg?.social_share_networks ?? [];

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(link);
  };

  const renderCopyToClipboard = () => {
    return (
      <a href="#" onClick={copyLinkToClipboard} title="Copy Link to Clipboard">
        <img src="/images/design/link.png" alt="Copy Link to Clipboard" />
      </a>
    );
  };

  const renderFacebook = () => {
    const finalLink = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
    return (
      <a href={finalLink} target="_blank" title="Share to Facebook">
        <img src="/images/design/facebook.png" alt="Share to Facebook" />
      </a>
    );
  };

  const renderTwitter = () => {
    // need encode!
    const finalLink = `https://twitter.com/intent/tweet/?text=${text}&url=${link}`;
    return (
      <a href={finalLink} target="_blank" title="Share to Twitter">
        <img src="/images/design/twitter.png" alt="Share to Twitter" />
      </a>
    );
  };

  return (
    <SocialLinksWrapper>
      {networksEnabled.includes('Twitter') && renderFacebook()}
      {networksEnabled.includes('Facebook') && renderTwitter()}
      {networksEnabled.includes('Copy To Clipboard') && renderCopyToClipboard()}
    </SocialLinksWrapper>
  );
};

export default SocialShareLinks;
